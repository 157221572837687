define("admin/controllers/locations/create-modal", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    createNewLocation: function (model) {
      model.location.client = model.client;
      return model.location.save().then(response => {
        model.locationsForSelect.removeObject(response);
        model.locationsForSelect.pushObject(response);
        model.mission.set('location', response);
        return this.send('close');
      }, response => {
        if (response.message && response.message.includes("Assertion Failed: 'location' was saved to the server, but the response returned the new id")) {
          const regex = /\d+/;
          const id = response.message.match(regex)[0];
          const location = this.store.peekRecord('location', id);
          model.mission.set('location', location);
          return this.send('close');
        } else {
          alert('There was an issue creating this location');
          return console.log('error while saving: ', response);
        }
      });
    },
    actions: {
      createLocation(model) {
        if (model.mission.id) {
          const data = model.mission.location.toJSON();
          data['mission_id'] = model.mission.id;
          data['client_id'] = model.mission.client.id;
          return Ember.$.ajax({
            url: `${_environment.default.api.host}/v1/admin/locations/${model.location.id}`,
            headers: this.get('sessionAccount.headers'),
            type: 'PUT',
            data,
            dataType: 'json'
          }).then(() => {
            return this.send('close');
          }, error_response => {
            let error = error_response.responseJSON.errors.firstObject;
            if (error) {
              alert("There was an issue saving this location: \n" + error.detail);
            }
            return console.log('something w wrong...', error_response);
          });
        } else {
          this.createNewLocation(model);
        }
      }
    }
  });
});