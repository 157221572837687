define("admin/adapters/capture-group-pilot", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: `${_environment.default.api.host}/${_environment.default.api.namespace}/admin`,
    urlTemplate: '{+namespace}/capture_groups/{captureGroupId}/pilots{/id}',
    urlSegments: {
      captureGroupId: function (type, id, snapshot) {
        return snapshot.belongsTo('capture_group', {
          id: true
        });
      }
    }
  });
});