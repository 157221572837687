define("admin/adapters/payout", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PayoutAdapter;
  PayoutAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/admin",
    createRecordUrlTemplate: '{+namespace}/{payoutableType}/{payoutableId}/payouts/{pilotId}',
    deleteRecordUrlTemplate: '{+namespace}/{payoutableType}/{payoutableId}/payouts{/id}',
    queryUrlTemplate: '{+namespace}/payouts{?query*}',
    urlSegments: {
      payoutableType: function (type, id, snapshot) {
        return snapshot.attr('saved_payoutable_type');
      },
      payoutableId: function (type, id, snapshot) {
        return snapshot.attr('saved_payoutable_id');
      },
      pilotId: function (type, id, snapshot) {
        return snapshot.belongsTo('pilot', {
          id: true
        });
      }
    }
  });
  var _default = _exports.default = PayoutAdapter;
});