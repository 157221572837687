define("admin/components/reschedule-modal", ["exports", "admin/config/environment", "admin/data/reschedule-options", "admin/data/reschedule-options-with-call"], function (_exports, _environment, _rescheduleOptions, _rescheduleOptionsWithCall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    rescheduleAllowed: true,
    sessionAccount: Ember.inject.service(),
    upcomingDays: [],
    rescheduleOptions: _rescheduleOptions.default,
    rescheduleOptionsWithCall: _rescheduleOptionsWithCall.default,
    init() {
      this._super();
      const rescheduler = this.get('sessionAccount.account');
      const mission_reschedule = this.get('model.mission.store').createRecord('mission_reschedule', {
        mission: this.get('model.mission'),
        scheduled_at_start: this.get('model.mission.scheduled_at_start'),
        scheduled_at_end: this.get('model.mission.scheduled_at_end'),
        rescheduler
      });
      this.set('missionReschedule', mission_reschedule);
      const promises = [];
      [1, 2, 3, 4, 5, 6, 7].forEach(day => {
        promises.push(this.get('model.mission').loadCapacityEstimations(day, this.get('sessionAccount.headers')));
      });
      Ember.RSVP.all(promises).then(array => {
        const availability_slots = [];
        array.map(function (response) {
          availability_slots.push(response[0]);
        });
        this.set('model.upcomingDays', availability_slots);
        return this.set('model.currDays', this.get('model.upcomingDays').slice(0, 3));
      }, error => {
        if (error.errors) {
          const message = error.errors[0].detail;
          this.set('scheduleError', message);
          return alert(message);
        }
      });
    },
    rescheduleChoiceObserver: Ember.observer('rescheduleChoice', function () {
      if (this.get('rescheduleChoice') === 'clear') {
        this.set('missionReschedule.scheduled_at_start', null);
        return this.set('missionReschedule.scheduled_at_end', null);
      }
    }),
    canSave: Ember.computed('rescheduleChoice', 'missionReschedule.reschedule_reason', function () {
      if (this.get('rescheduleChoice')) {
        if (this.get('rescheduleChoice') === 'pilotAvailability') {
          return this.get('missionReschedule.reschedule_reason') && this.get('selectedTimeslotData');
        } else {
          return this.get('missionReschedule.reschedule_reason');
        }
      }
    }),
    scheduleErrorObserver: Ember.observer('selectedStart', 'selectedEnd', function () {
      if (this.get('scheduleError')) {
        return this.set('scheduleError', false);
      }
    }),
    showClearDateCancelPilot: Ember.computed('model.mission.isCallToSchedule', 'model.mission.pilot.id', function () {
      return this.get('model.mission.isCallToSchedule') && this.get('model.mission.pilot.id') != null;
    }),
    promiseDateDisabled: Ember.computed('missionReschedule.reschedule_reason', function () {
      return this.get('missionReschedule.reschedule_reason')?.get('promised_date_flexibility') != true;
    }),
    actions: {
      setStartTime(time) {
        return this.set('scheduledStartTime', time);
      },
      setEndTime(time) {
        return this.set('scheduledEndTime', time);
      },
      setRescheduleReason(reasonId) {
        const reason_object = this.get('model.mission.store').peekRecord('reschedule-reason', reasonId);
        return this.set('missionReschedule.reschedule_reason', reason_object);
      },
      setRescheduleChoice(choice) {
        return this.set('rescheduleChoice', choice);
      },
      setSelected(data) {
        this.set('selectedTimeslotData', data);
        if (this.get('missionReschedule.reschedule_reason').get('id')) {
          return this.set('canSave', true);
        }
      },
      save() {
        this.get('model.mission').save(); // persist any delivery date updates
        if (this.get('rescheduleChoice') === 'clear') {
          Ember.$.ajax({
            url: `${_environment.default.api.host}/v1/admin/missions/${this.get('missionReschedule.mission.id')}/schedule`,
            headers: this.get('sessionAccount.headers'),
            type: 'DELETE',
            dataType: 'json',
            data: {
              notes: this.get('missionReschedule.notes'),
              reschedule_reason_id: this.get('missionReschedule.reschedule_reason.slug')
            }
          }).then(() => {
            return location.reload();
          }).catch(response => {
            return alert(response.errors[0].detail);
          });
        } else if (this.get('rescheduleChoice') === 'clearDateCancelPilot') {
          return this.get('missionReschedule').clear().then(() => {
            return location.reload();
          }).catch(response => {
            return alert(response.errors[0].detail);
          });
        } else {
          const data = {
            'reason_id': this.get('missionReschedule.reschedule_reason').get('id'),
            'notes': this.get('missionReschedule.notes')
          };
          if (this.get('rescheduleChoice') === 'pilotAvailability') {
            data['mission_scheduled_at_start'] = this.get('selectedTimeslotData')['scheduled_at_start'];
            data['mission_scheduled_at_end'] = this.get('selectedTimeslotData')['scheduled_at_end'];
            data['capacity_data'] = this.get('selectedTimeslotData');
          } else {
            data['mission_scheduled_at_start'] = this.get('selectedStart');
            data['mission_scheduled_at_end'] = this.get('selectedEnd');
          }
          this.send('close');
          this.set('model.scheduleDataForUpdate', data);
          return this.confirmAction(this.get('model'));
        }
      },
      close() {
        return this.get('close')();
      }
    }
  });
});