define("admin/components/missions/plan-map/remove-map-feature-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      toggleAction: function () {
        this.get('closeAction')();
      },
      tryToRemove: function () {
        if (this.get('feature.circle')) {
          this.get('feature.circle').setMap(null);
        }
        this.get('feature.map_feature').deleteRecord();
        return this.get('feature.map_feature').save().then(() => {
          // w00t
          this.get('closeAction')(this.feature);
        }, function (error) {
          console.error(error);
          alert("There was an error deleting this map feature: \n\n" + error.errors.mapBy('detail').join(', '));
        });
      }
    }
  });
});