define("admin/data/quality-check-issues", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: 'Blurriness',
    value: 'is_blurry_image'
  }, {
    label: 'Low Resolution',
    value: 'is_low_resolution'
  }, {
    label: 'Missing GPS Meta Data',
    value: 'missing_gps_info'
  }, {
    label: 'Missing RTK Meta Data',
    value: 'missing_rtk_data'
  }, {
    label: 'Altitude Out of Target Range',
    value: 'altitude_is_out_of_target'
  }];
});