define("admin/adapters/pilot", ["exports", "admin/adapters/application", "admin/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    namespace: _environment.default.api.namespace + '/admin',
    deletePayoutAccount: (pilotId, stripeAccountId, headers) => {
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/pilots/${pilotId}/stripe_accounts/${stripeAccountId}`,
        type: 'DELETE',
        dataType: 'json',
        headers
      });
    },
    deleteLegacyPayoutAccount: (pilotId, headers) => {
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/pilots/${pilotId}/payout_accounts`,
        type: 'DELETE',
        dataType: 'json',
        headers
      });
    }
  });
});