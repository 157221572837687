define("admin/components/site-inspections/standby-hours-line-item-rejected-reason", ["exports", "moment-timezone", "admin/helpers/humanize-seconds", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response"], function (_exports, _momentTimezone, _humanizeSeconds, _sendErrorToSentry, _getErrorMessageFromResponse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    inputDate: null,
    // to avoid observer on date
    lineItemDetails: null,
    status: 'approved',
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    init() {
      this._super();
    },
    didReceiveAttrs() {
      _momentTimezone.default.tz.setDefault(this.get('workOrder.timezone'));
      this.initStandByHoursLineItem();
    },
    DATE_FORMAT: 'MM/DD/YY',
    formatDate(date) {
      const isValid = (0, _momentTimezone.default)(date).isValid();
      return isValid ? (0, _momentTimezone.default)(date).format(this.DATE_FORMAT) : null;
    },
    formatTime(date) {
      const isValid = (0, _momentTimezone.default)(date).isValid();
      return isValid ? (0, _momentTimezone.default)(date).format('hh:mm') : null;
    },
    initStandByHoursLineItem() {
      const details = {};
      const lineItem = this.get('model.standbyHoursLineItems').find(standbyHoursLineItem => standbyHoursLineItem.id === this.get('selectedStandbyHoursLineItem.id'));
      details['date'] = this.formatDate(lineItem.starts_at);
      details['timeRange'] = `${this.formatTime(lineItem.starts_at)} - ${this.formatTime(lineItem.ends_at)}`;
      details['totalTime'] = (0, _humanizeSeconds.humanizeSeconds)(lineItem.periodInSeconds);
      details['reason'] = lineItem.standby_hours_reason.get('name');
      details['description'] = lineItem.description;
      details['reasons'] = this.get('model.lineItemRejectionReasons');
      this.set('model.standbyHoursLineItems.rejection_reason', lineItem.rejection_reason);
      this.set('model.standbyHoursLineItems.rejection_comment', lineItem.rejection_comment);
      this.set('lineItemDetails', details);
    },
    close() {
      this.closeAction();
    },
    actions: {
      close() {
        this.close();
      },
      rejectStandbyHoursLineItem(lineItem) {
        const payload = {
          status: 'rejected',
          rejection_reason: this.get('model.standbyHoursLineItems.rejection_reason'),
          rejection_comment: this.get('model.standbyHoursLineItems.rejection_comment')
        };
        let adapter = this.store.adapterFor('standby-hours-line-item');
        return adapter.updateStandbyHoursLineItem(this.get('sessionAccount.headers'), lineItem.site_inspection.get('number'), lineItem.id, payload).then(response => {
          const lineItemDate = this.formatDate(lineItem.starts_at);
          this.flashMessages.success(`Standby time ${lineItemDate} ${(0, _humanizeSeconds.humanizeSeconds)(lineItem.periodInSeconds)} was rejected`);
          this.store.pushPayload(response);
          this.close();
        }).catch(response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          this.flashMessages.error('Failed to update status');
        });
      }
    }
  });
});