define("admin/routes/shot_lists/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model() {
      return this.store.query('shot_list', {});
    }
  });
});