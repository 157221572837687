define("admin/routes/inventories-lists/dropships-list", ["exports", "ember-infinity/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, {
    model: function () {
      var inventoryModel = this.modelFor('inventories-lists');
      return Ember.RSVP.hash({
        inventoryItems: inventoryModel.inventoryItems,
        dropships: inventoryModel.dropships
      });
    },
    setupController: function (controller, model) {
      this.controllerFor('inventories-lists').set('activeTab', 'dropships-list');
      this._super(controller, model);
    }
  });
});