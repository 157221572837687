define("admin/validations/standby-hours-line-item", ["exports", "ember-changeset-validations/validators", "ember-changeset-validations/utils/validation-errors", "moment"], function (_exports, _validators, _validationErrors, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const startsAtValidator = function () {
    return (key, newValue, oldValue, changes, content) => {
      if (Ember.isEmpty(newValue)) {
        return (0, _validationErrors.default)('From', {
          type: 'present',
          value: newValue
        });
      } else if (!(0, _moment.default)(newValue).isValid()) {
        return (0, _validationErrors.default)('From', {
          type: 'date',
          value: newValue
        });
      } else if ((changes.ends_at || content.ends_at) && (0, _moment.default)(newValue).isSameOrAfter((0, _moment.default)(changes.ends_at || content.ends_at))) {
        return "From can't be the same or after To";
      } else {
        return true;
      }
    };
  };
  const endsAtValidator = function () {
    return (key, newValue, oldValue, changes, content) => {
      if (Ember.isEmpty(newValue)) {
        return (0, _validationErrors.default)('To', {
          type: 'present',
          value: newValue
        });
      } else if (!(0, _moment.default)(newValue).isValid()) {
        return (0, _validationErrors.default)('To', {
          type: 'date',
          value: newValue
        });
      } else if ((changes.starts_at || content.starts_at) && (0, _moment.default)(newValue).isSameOrBefore((0, _moment.default)(changes.starts_at || content.starts_at))) {
        return "To can't be the same or before From";
      } else {
        return true;
      }
    };
  };
  var _default = _exports.default = {
    'pilot': (0, _validators.validatePresence)(true),
    'site_inspection': (0, _validators.validatePresence)(true),
    'standby_hours_reason': (0, _validators.validatePresence)({
      presence: true,
      message: 'Enter the reason'
    }),
    'starts_at': startsAtValidator(),
    'ends_at': endsAtValidator()
  };
});