define("admin/components/reusable/show-hide-next-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    whichDirection: Ember.computed('hide', function () {
      if (this.hide) {
        Ember.$('.' + this.source).css('visibility', 'collapse');
        return 'down';
      } else {
        Ember.$('.' + this.source).css('visibility', '');
        return 'up';
      }
    }),
    didInsertElement() {
      this._super(...arguments);
      // trigger computed property after DOM is rendered
      this.notifyPropertyChange('whichDirection');
    },
    actions: {
      toggleRow() {
        this.toggleProperty('hide');
      }
    }
  });
});