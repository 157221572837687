define("admin/components/mission-assets-bucket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['mission-assets-bucket'],
    uploadedAttachments: Ember.computed('bucket.attachments.[].name', function () {
      return this.get('bucket.attachments').getEach('name');
    }),
    actions: {
      promoteAll: function () {
        var request = this.get('bucket').promoteAssets().then(response => {
          this.store.pushPayload(response);
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      },
      addAsset: function (file) {
        this.onfileadd(file, {
          "bucket_id": this.get('bucket.id'),
          "bucket_type_slug": this.get('bucket.bucket_type.slug')
        });
      },
      removeAsset: function (file, payload) {
        this.onfileremove(file, payload);
      },
      startUpload: function (uploader) {
        this.onstartupload(uploader);
      },
      downloadAsset: function (asset_url) {
        window.location = asset_url;
      },
      toggleSourceType: function (asset) {
        if (asset.get('source_type') == 'raw') {
          asset.set('source_type', 'edit');
        } else {
          asset.set('source_type', 'raw');
        }
        var request = asset.save().then(() => {}).catch(() => {
          if (asset.get('source_type') == 'raw') {
            asset.set('source_type', 'edit');
          } else {
            asset.set('source_type', 'raw');
          }
          alert("There was an error updating asset #{asset.get('id')}");
        });
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
        return request;
      },
      deleteAsset: function (asset) {
        if (asset && window.confirm('Are you sure? This will permanently remove this asset.')) {
          asset.destroyRecord();
        }
      }
    }
  });
});