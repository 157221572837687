define("admin/components/mission/assets/shot-thumbnail", ["exports", "admin/components/mission/assets/shot-base", "admin/data/allowed-extensions"], function (_exports, _shotBase, _allowedExtensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _shotBase.default.extend({
    classNames: ['mission-assets-shot-thumbnail'],
    showAssetsCount: 12,
    assetUploadExtensions: [..._allowedExtensions.extendedList, ..._allowedExtensions.dataFileExtensions, ..._allowedExtensions.logFileExtensions].join(','),
    orthoUploadExtensions: _allowedExtensions.orthoExtensions.join(','),
    threeDModelUploadExtensions: _allowedExtensions.threeDModelExtensions.join(','),
    isMultiPageImages: Ember.computed('shot.imagePagingMeta', function () {
      return this.get('shot.imagePagingMeta.total_pages') > 1;
    }),
    seeMoreLink: Ember.computed('shot.imagePagingMeta', function () {
      if (this.isMultiPageImages) {
        return `See ${this.shot.imagePagingMeta.per_page - this.showAssetsCount} more of ${this.shot.imagePagingMeta.total_count}`;
      } else {
        return `See ${this.showMoreAssetsCount} more`;
      }
    }),
    showProcessingError: Ember.computed('showProcessingStatus', 'shot.{errorDataFilesCount,errorImagesCount,errorVideosCount,errorOrthosCount}', function () {
      if (this.get('showProcessingStatus')) {
        return false;
      }
      return this.get('shot.errorDataFilesCount') || this.get('shot.errorImagesCount') || this.get('shot.errorVideosCount') || this.get('shot.errorOrthosCount');
    }),
    showAeroProcessing: Ember.computed('shot.data_files.[]', function () {
      if (this.shot.get('name') == 'Turbine Title Cards') {
        return this.shot.get('data_files').filter(data_file => data_file.get('name').includes("aero_")).length > 0;
      }
      return false;
    }),
    shotImagesAltitudeRange: Ember.computed('shot.imagesAltitudeRange', function () {
      if (!this.shot.get('imagesAltitudeRange')) return;
      return this.shot.get('imagesAltitudeRange').join('-');
    }),
    actions: {
      goToAsset(asset, index) {
        this.goToAsset(asset, index);
      },
      openFullView() {
        this.get('shot').loadImages();
        this.openFullViewAction(this.get('shot'));
      },
      aeroOperation(missionId, operationType) {
        return this.get('store').adapterFor('shot-thumbnail').aeroOperations(this.get('sessionAccount.headers'), missionId, operationType).then(() => {
          alert(operationType + ' request is successfully pushed');
          return location.reload();
        }).catch(() => {
          return alert('Error reprocessing aero');
        });
      }
    }
  });
});