define("admin/components/missions/partial-reshoot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedShots: [],
    partialReshoot: null,
    errors: null,
    init: function () {
      if (!this.get('partialReshoot')) {
        let record = this.get('store').createRecord('partial-reshoot', {
          clear_shots: true
        });
        record.set('mission', this.get('model.mission'));
        this.set('partialReshoot', record);
      }
      this._super(...arguments);
    },
    requireCyberduck: Ember.computed('model.mission.mission_flight_app', 'model.mission.mission_flight_app.flight_app', function () {
      return this.get('model.mission.mission_flight_app.flight_app.deliver_to_app') == 'cyberduck';
    }),
    missionErrors: Ember.computed('errors', 'partialReshoot', 'partialReshoot.errors.content.[]', 'partialReshoot.errors.length', function () {
      return this.get('partialReshoot.errors').get('mission');
    }),
    reasonErrors: Ember.computed('errors', 'partialReshoot', 'partialReshoot.errors.content.[]', 'partialReshoot.errors.length', function () {
      return this.get('partialReshoot.errors').get('reason');
    }),
    shotsErrors: Ember.computed('errors', 'partialReshoot', 'partialReshoot.errors.content.[]', 'partialReshoot.errors.length', function () {
      return this.get('partialReshoot.errors').get('shots');
    }),
    shotColumns: Ember.computed('model.mission', 'model.mission.shots', function () {
      let shots = this.get('model.mission.shots');
      if (shots.length <= 5) {
        return [shots];
      }
      let half = Math.ceil(shots.length / 2);
      return [shots.slice(0, half), shots.slice(half, shots.length)];
    }),
    partialReshootReasons: Ember.computed('model.rejectionReasons', function () {
      return this.get('model.rejectionReasons').filter(element => {
        return element.get('is_partial_reshoot_reason');
      });
    }),
    actions: {
      setRejectionReason(reasonObject) {
        this.get('setRejectionReasonSlug')(reasonObject.slug);
        this.get('partialReshoot').set('reason', reasonObject);
      },
      selectAllShots: function (event) {
        if (event.target.checked) {
          this.get('model.mission.shots').forEach(shot => {
            this.get('partialReshoot.shots').pushObject(shot);
          });
        } else {
          this.set('partialReshoot.shots', []);
        }
      },
      close: function () {
        return this.close();
      },
      save: function () {
        const partialReshoot = this.get('partialReshoot');
        const _this = this;
        // persist delivery date changes
        this.get('model.mission').save().then(() => {
          if (!partialReshoot.validate()) {
            window.test = partialReshoot.errors;
            _this.set('errors', partialReshoot.errors);
            return;
          }
          partialReshoot.save().then(() => {
            _this.get('model.mission').reload();
            _this.set('errors', partialReshoot.errors);
            _this.close();
          }).catch(function () {
            _this.set('errors', partialReshoot.errors);
          });
        });
      },
      clearPartialReshootShots() {
        this.set('partialReshoot.shots', []);
      }
    }
  });
});