define("admin/components/capacity-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      close: function () {
        return this.get('close')();
      },
      setSelected: function (data) {
        this.set('selectedTimeslotData', data);
      },
      saveMission: function () {
        let data = {
          'mission_scheduled_at_start': this.get('selectedTimeslotData')['scheduled_at_start'],
          'mission_scheduled_at_end': this.get('selectedTimeslotData')['scheduled_at_end'],
          'capacity_data': this.get('selectedTimeslotData'),
          'reason_id': this.get('model.mission.store').peekAll('reschedule-reason').filterBy('slug', 'initial_schedule')[0].id
        };
        this.get('close')();
        this.set('model.scheduleDataForUpdate', data);
        return this.confirmAction(this.get('model'));
      }
    }
  });
});