define("admin/components/site-inspections/standby-hours-tab", ["exports", "moment-timezone", "admin/helpers/humanize-seconds", "admin/utils/send-error-to-sentry", "admin/utils/get-error-message-from-response", "admin/data/standby-hours/missing-standby-hours", "admin/data/standby-hours/standby-hour-status"], function (_exports, _momentTimezone, _humanizeSeconds, _sendErrorToSentry, _getErrorMessageFromResponse, _missingStandbyHours, _standbyHourStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    standbyHourStatusSelectedValue: 'all',
    selectedPilotId: '',
    showStandbyHoursLineItemModal: false,
    showStandbyHoursLineItemRejectedReasonModal: false,
    actionType: '',
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    missingStandbyHours: _missingStandbyHours.default,
    standbyHourStatus: _standbyHourStatus.default,
    didReceiveAttrs() {
      _momentTimezone.default.tz.setDefault(this.get('workOrder.timezone'));
    },
    didDestroyElement() {
      _momentTimezone.default.tz.setDefault();
    },
    DATE_FORMAT: 'MM/DD/YY',
    formatDate(date) {
      const isValid = (0, _momentTimezone.default)(date).isValid();
      return isValid ? (0, _momentTimezone.default)(date).format(this.DATE_FORMAT) : null;
    },
    pilotLineItemsReport: Ember.computed('standByHoursLineItemsByPilot', function () {
      const hash = {};
      for (const pilotId in this.get('standByHoursLineItemsByPilot')) {
        hash[pilotId] = {
          approved: 0,
          rejected: 0,
          in_review: 0,
          total: 0,
          assets_completed: 0,
          lineItems: []
        };
        this.get('standByHoursLineItemsByPilot')[pilotId].reduce((object, lineItem) => {
          let status = lineItem.get('status') || 'in_review';
          hash[pilotId][status] += lineItem.get('periodInSeconds');
          hash[pilotId]['assets_completed'] = this.get('comepletedMissions');
          hash[pilotId]['total'] += lineItem.get('periodInSeconds');
          hash[pilotId]['lineItems'].addObject(lineItem);
        }, {});
      }
      return hash;
    }),
    filteredLineItemsByPilotId: Ember.computed('standByHoursLineItemsByPilot', 'standbyHourStatusSelectedValue', 'selectedPilotId', function () {
      const pilotId = this.get('selectedPilotId');
      const status = this.get('standbyHourStatusSelectedValue');
      return this.get('workOrder.pilots').reduce((hash, pilot) => {
        hash[pilot.id] = pilot.get('standby_hours_line_items').filter(lineItem => {
          return ['all', lineItem.status].includes(status) || pilot.id !== pilotId;
        });
        return hash;
      }, {});
    }),
    comepletedMissions: Ember.computed('workOrder.missions', function () {
      const completed_missions = this.get('workOrder.missions').filter(mission => {
        return mission.status === 'complete';
      });
      return completed_missions.length;
    }),
    groupedByDateLineItems: Ember.computed('filteredLineItemsByPilotId', function () {
      const hash = {};
      for (const pilotId in this.get('filteredLineItemsByPilotId')) {
        hash[pilotId] = this.get('filteredLineItemsByPilotId')[pilotId].reduce((object, lineItem) => {
          const date = (0, _momentTimezone.default)(lineItem.get('starts_at')).format('MM/DD/YY');
          (object[date] = object[date] || []).push(lineItem);
          if (object[date].length > 1) {
            object[date].sort((a, b) => (0, _momentTimezone.default)(a.get('starts_at')).valueOf() - (0, _momentTimezone.default)(b.get('starts_at').valueOf()));
          }
          return object;
        }, {});
      }
      return hash;
    }),
    totalTimeByDateLineItems: Ember.computed('groupedByDateLineItems', function () {
      const hash = {};
      for (const pilotId in this.get('groupedByDateLineItems')) {
        hash[pilotId] = {};
        for (const date in this.get('groupedByDateLineItems')[pilotId]) {
          hash[pilotId][date] = this.get('groupedByDateLineItems')[pilotId][date].reduce((sum, item) => sum + item.get('periodInSeconds'), 0);
        }
      }
      return hash;
    }),
    approvedTimeByDateLineItems: Ember.computed('groupedByDateLineItems', function () {
      const hash = {};
      for (const pilotId in this.get('groupedByDateLineItems')) {
        hash[pilotId] = {};
        for (const date in this.get('groupedByDateLineItems')[pilotId]) {
          hash[pilotId][date] = this.get('groupedByDateLineItems')[pilotId][date].filter(lineItem => lineItem.status === 'approved').reduce((sum, item) => sum + item.get('periodInSeconds'), 0);
        }
      }
      return hash;
    }),
    comepletedMissionsCountByDate: Ember.computed('pilotLineItemsReport', function () {
      _momentTimezone.default.tz.setDefault(this.get('workOrder.timezone'));
      const hash = {};
      for (const pilotId in this.get('groupedByDateLineItems')) {
        hash[pilotId] = {};
        const missionDates = this.get('workOrder.missions').filter(mission => {
          return mission.get('pilot.id') === pilotId;
        }).map(mission => (0, _momentTimezone.default)(mission.get('captured_on'), 'YYYY:MM:DD HH:mm:ss').format('MM/DD/YY'));
        for (const date in this.get('groupedByDateLineItems')[pilotId]) {
          hash[pilotId][date] = missionDates.filter(curDate => {
            return curDate === date || 0;
          }).length;
        }
      }
      return hash;
    }),
    actions: {
      toggleStandbyHoursModal(pilot = null) {
        this.set('selectedStandbyHoursPilot', pilot);
        this.toggleProperty('showStandbyHoursLineItemModal');
        this.set('standbyHourStatusSelectedValue', 'all');
        this.send('filterStandbyHoursLineItemByStatus', pilot?.id, this.get('standbyHourStatusSelectedValue'), 'newLineItem');
      },
      toggleStandbyHoursRejectReasonModal(lineItem) {
        this.set('selectedStandbyHoursLineItem', lineItem);
        this.toggleProperty('showStandbyHoursLineItemRejectedReasonModal');
        this.set('standbyHourStatusSelectedValue', 'all');
        this.set('actionType', 'update');
        this.send('filterStandbyHoursLineItemByStatus', this.get('selectedPilotId'), this.get('standbyHourStatusSelectedValue'), 'updateLineItem');
      },
      updateStandbyHoursLineItem(lineItem, actionType, value) {
        const payload = actionType == 'statusUpdate' ? {
          status: value,
          rejection_reason: '',
          rejection_comment: ''
        } : {
          billable_to_customer: value.target.checked
        };
        let adapter = this.store.adapterFor('standby-hours-line-item');
        return adapter.updateStandbyHoursLineItem(this.get('sessionAccount.headers'), this.get('workOrder.number'), lineItem.id, payload).then(response => {
          const lineItemDate = this.formatDate(lineItem.starts_at);
          if (actionType === 'statusUpdate') {
            this.flashMessages.success(`Standby time ${lineItemDate} ${(0, _humanizeSeconds.humanizeSeconds)(lineItem.periodInSeconds)} was ${value}`);
          }
          this.store.pushPayload(response);
          this.set('actionType', 'update');
          this.send('filterStandbyHoursLineItemByStatus', response.data.relationships.pilot.data.id, this.get('standbyHourStatusSelectedValue'), 'updateLineItem');
        }).catch(response => {
          (0, _sendErrorToSentry.default)((0, _getErrorMessageFromResponse.default)(response));
          this.flashMessages.error('Failed to update status');
        });
      },
      filterStandbyHoursLineItemByStatus(pilotId, status, actionType = 'filter') {
        this.set('actionType', actionType);
        this.set('standbyHourStatusSelectedValue', status);
        this.set('selectedPilotId', pilotId);
      }
    }
  });
});