define("admin/controllers/clients/client/missions/index", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['activeTab', 'missions_query', 'sites_query', 'sitesSortProps'],
    csvSortProps: ['created_at:desc'],
    sitesSortProps: ['updated:desc'],
    missionsSortProps: ['updated:desc'],
    activeTab: 'clientMissions',
    sessionAccount: Ember.inject.service(),
    sortedCsvs: Ember.computed.sort('model.client.client_mission_csvs', 'csvSortProps'),
    sortedClientPackages: Ember.computed('model.client_packages_array.[]', function () {
      return this.get('model.client_packages_array').sortBy('vertical.short_name', 'name', 'price');
    }),
    availableOrganizationPackages: Ember.computed('model.client.organization.packages', 'model.client.client_available_packages.[]', function () {
      if (this.get('model.client.organization')) {
        const availablePackages = this.get('model.client.client_available_packages');
        return this.get('model.client.organization.packages').filter(orgPack => availablePackages.findBy('package', orgPack));
      }
    }),
    actions: {
      refresh: function () {
        this.get('model.client').reload();
      },
      setTab: function (tab) {
        this.set('activeTab', tab);
      },
      duplicate: function (missionId) {
        const confirmDuplicate = confirm("Are you sure you want to duplicate this mission?");
        if (confirmDuplicate) {
          Ember.$.ajax({
            url: `${_environment.default.api.host}/v1/admin/missions/${missionId}/duplicate`,
            type: 'POST',
            dataType: 'json',
            headers: this.get('sessionAccount.headers')
          }).then(response => {
            window.open(`${window.location.origin}/missions/${response.mission_id}/edit`);
          }, error => {
            console.log('failed to duplicate', error);
          });
        }
      },
      setSitesSort: function (sort) {
        this.set('sitesSortProps', [sort]);
      },
      setMissionsSort: function (sort) {
        this.set('missionsSortProps', [sort]);
      },
      clearQuery: function () {
        this.set('missions_query', null);
      },
      clearSitesQuery: function () {
        this.set('sites_query', null);
      }
    }
  });
});