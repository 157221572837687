define("admin/routes/shot_lists/edit", ["exports", "admin/mixins/s3-asset-uploads", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _s3AssetUploads, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_s3AssetUploads.default, _authenticatedRouteMixin.default, {
    model(params) {
      return Ember.RSVP.hash({
        shot_list: this.store.findRecord('shot_list', params.shot_list_id),
        post_processes: this.store.findAll('post-process')
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      controller.set('currentRoute', this);
    },
    actions: {
      busy: function (promise) {
        let body = Ember.$('body');
        body.addClass('busy');
        this.set('context.busy', true);
        var callback = () => {
          body.removeClass('busy');
          this.set('context.busy', false);
        };
        if (promise.finally) {
          promise.finally(callback);
        } else {
          promise.always(callback);
        }
      }
    }
  });
});