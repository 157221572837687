define("admin/components/collapsible-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    open: true,
    didInsertElement: function () {
      this._super(...arguments);
      Ember.$(this.element);
    },
    actions: {
      toggle: function () {
        this.toggleProperty('open');
      }
    }
  });
});