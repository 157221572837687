define("admin/services/session-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    account: Ember.computed('session.data.authenticated', function () {
      if (this.get('session.data.authenticated.token')) {
        return this.get('store').findRecord('admin', this.get('session.data.authenticated.id'));
      }
    }),
    headers: Ember.computed('session.data.authenticated.token,session.data.authenticated.email', function () {
      return {
        "Authorization": `Token token=\"${this.get('session.data.authenticated.token')}\", email=\"${this.get('session.data.authenticated.email')}\"`
      };
    }),
    // Url params which are used for authenticating panorama iframe requests
    urlParams: Ember.computed('session.data.authenticated.token,session.data.authenticated.email', function () {
      const email = encodeURIComponent(`${this.get('session.data.authenticated.email')}`);
      return `?auth=${this.get('session.data.authenticated.token')},${email}&full_screen=1`;
    })
  });
});