define("admin/components/site-inspections/filter-inspections", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DATE_FORMAT = 'MM/DD/YYYY';
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    classNames: ["filter-inspections-sidebar"],
    legalEntities: Ember.A([]),
    pilotSelectedIds: Ember.A([]),
    clientSelectedIds: Ember.A([]),
    organizationSelectedIds: Ember.A([]),
    countries: null,
    countryCodes: Ember.A([]),
    didInsertElement() {
      const pilotSelectedIds = localStorage.getItem('pilotSelectedIds');
      if (pilotSelectedIds) {
        this.set('pilotSelectedIds', JSON.parse(pilotSelectedIds));
      }
      const clientSelectedIds = localStorage.getItem('clientSelectedIds');
      if (clientSelectedIds) {
        this.set('clientSelectedIds', JSON.parse(clientSelectedIds));
      }
      const organizationSelectedIds = localStorage.getItem('organizationSelectedIds');
      if (organizationSelectedIds) {
        this.set('organizationSelectedIds', JSON.parse(organizationSelectedIds));
      }
    },
    didRender() {
      if (this.get('selectedStatuses')) {
        for (let status of this.get('selectedStatuses').split(',')) {
          Ember.$(`input.status-checkbox[data-status="${status}"]`).prop('checked', true);
        }
      }
      if (this.get('selectedLegalEntities')) {
        for (let legalEntity of this.get('selectedLegalEntities').split(',')) {
          Ember.$(`input.legal-entity-checkbox[data-status="${legalEntity}"]`).prop('checked', true);
        }
      }
      [["target_start_date_start", "startDateStart"], ["target_start_date_end", "startDateEnd"], ["target_end_date_start", "endDateStart"], ["target_end_date_end", "endDateEnd"], ["committed_start_date_start", "committedDateStart"], ["committed_start_date_end", "committedDateEnd"]].forEach(([dateQueryParam, date]) => {
        if (this.get(dateQueryParam)) {
          this.set(date, moment(this.get(dateQueryParam)).format(DATE_FORMAT));
        }
      });
      Ember.$('.start-day-filter').datetimepicker({
        collapse: true,
        focusOnShow: false,
        viewMode: 'days',
        format: DATE_FORMAT,
        useCurrent: false,
        icons: {
          date: "fa fa-calendar",
          previous: "fa fa-angle-left",
          next: "fa fa-angle-right",
          close: "icon-Xmark"
        }
      });
      Ember.$('.end-day-filter').datetimepicker({
        collapse: true,
        focusOnShow: false,
        viewMode: 'days',
        format: DATE_FORMAT,
        useCurrent: false,
        icons: {
          date: "fa fa-calendar",
          previous: "fa fa-angle-left",
          next: "fa fa-angle-right",
          close: "icon-Xmark"
        }
      });
    },
    setStatuses() {
      let statuses = [];
      Ember.$('input.status-checkbox').each(function (index, checkbox) {
        if (checkbox.checked) {
          statuses.push(checkbox.value);
        }
      });
      this.set('selectedStatuses', statuses.join());
    },
    setLegalEntities() {
      let legalEntities = [];
      Ember.$('input.legal-entity-checkbox').each(function (index, checkbox) {
        if (checkbox.checked) {
          legalEntities.push(checkbox.value);
        }
      });
      this.set('selectedLegalEntities', legalEntities.join());
    },
    requestEntity(headers, endpoint, searchQueryParam) {
      const encodedQuery = encodeURIComponent(searchQueryParam);
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/${endpoint}?quick_search=true&q=${encodedQuery}`,
        headers,
        type: 'GET',
        dataType: 'json'
      });
    },
    debounceAutocompleteRequest(requestFunc, timeoutId) {
      clearTimeout(timeoutId);
      if (this.get('autocompleteEnabled', true)) {
        return setTimeout(requestFunc.bind(this), 500);
      }
    },
    handleClickOutsideAutocomplete(target, inputPrefix, timeoutId) {
      if (!target.closest('.autocomplete-list') && target !== document.querySelector(`#${inputPrefix}SearchInput`)) {
        this.set(`${inputPrefix}SearchInput`, null);
        clearTimeout(timeoutId);
      }
    },
    updateAutoCompleteList(requestObjectsFunction, objectType) {
      const inputVal = this.get(`${objectType}SearchInput`);
      const headers = this.get('sessionAccount.headers');
      const pluralEndpoint = objectType + 's'; //simple and works for the three current use cases
      if (inputVal.length >= 3) {
        return requestObjectsFunction(headers, pluralEndpoint, inputVal).then(response => {
          const list = [];
          for (let row of response.data) {
            var name = this.autoCompleteName(row, objectType);
            var option = this.autoCompleteName(row, objectType);
            if (row.attributes.email) {
              option += ` (${row.attributes.email})`;
            }
            var email = row.attributes.email;
            if (!this.get(`${objectType}SelectedIds`).find(p => p.id === row.id)) {
              list.push({
                id: row.id,
                name,
                email,
                option
              });
            }
          }
          this.set(`${objectType}AutocompleteList`, list);
        });
      } else {
        this.set(`${objectType}AutocompleteList`, []);
      }
    },
    autoCompleteName(row, objectType) {
      if (objectType == 'client' || objectType == 'pilot') {
        return `${row.attributes.first_name} ${row.attributes.last_name}`;
      } else if (objectType == 'organization') {
        return row.attributes.name;
      } else {
        return '';
      }
    },
    inputType: Ember.computed('bulkUpdateEnabled', function () {
      return this.get('bulkUpdateEnabled') ? 'radio' : 'checkbox';
    }),
    actions: {
      toggleFilter() {
        this.set('hideFilter', true);
      },
      updateInspectionStatuses() {
        Ember.run.debounce(this, this.setStatuses, 1500);
      },
      updateLegalEntities() {
        Ember.run.debounce(this, this.setLegalEntities, 1500);
      },
      updateSelectedPilots(pilotSelectedIds) {
        this.set('pilotSelectedIds', pilotSelectedIds);
        localStorage.setItem('pilotSelectedIds', JSON.stringify(pilotSelectedIds));
      },
      changeCountryCodesAction(countryCodes) {
        this.changeCountryCodes(countryCodes);
      },
      clearInspectionStatuses() {
        Ember.$("input.status-checkbox").prop('checked', false);
        this.set('selectedStatuses', null);
      },
      clearLegalEntities() {
        Ember.$("input.legal-entity-checkbox").prop('checked', false);
        this.set('selectedLegalEntities', null);
      },
      setStartDateRange() {
        const localStartDate = this.get('startDateStart');
        const localEndDate = this.get('startDateEnd');
        const localFormat = DATE_FORMAT;
        if (localStartDate) {
          const utcStartDate = moment.utc(moment(localStartDate, localFormat).utc().format('YYYY-MM-DD HH:mm:ssZ'));
          this.set('target_start_date_start', utcStartDate.format(moment.defaultFormat));
        } else {
          this.set('target_start_date_start', null);
        }
        if (localEndDate) {
          const utcEndDate = moment.utc(moment(localEndDate, localFormat).endOf('day').utc().format('YYYY-MM-DD HH:mm:ssZ'));
          this.set('target_start_date_end', utcEndDate.format(moment.defaultFormat));
        } else {
          this.set('target_start_date_end', null);
        }
      },
      clearStartDateRange() {
        this.set('startDateStart', null);
        this.set('startDateEnd', null);
        this.set('target_start_date_start', null);
        this.set('target_start_date_end', null);
      },
      setEndDateRange() {
        const localStartDate = this.get('endDateStart');
        const localEndDate = this.get('endDateEnd');
        const localFormat = DATE_FORMAT;
        if (localStartDate) {
          const utcStartDate = moment.utc(moment(localStartDate, localFormat).utc().format('YYYY-MM-DD HH:mm:ssZ'));
          this.set('target_end_date_start', utcStartDate.format(moment.defaultFormat));
        } else {
          this.set('target_end_date_start', null);
        }
        if (localEndDate) {
          const utcEndDate = moment.utc(moment(localEndDate, localFormat).endOf('day').utc().format('YYYY-MM-DD HH:mm:ssZ'));
          this.set('target_end_date_end', utcEndDate.format(moment.defaultFormat));
        } else {
          this.set('target_end_date_end', null);
        }
      },
      clearEndDateRange() {
        this.set('endDateStart', null);
        this.set('endDateEnd', null);
        this.set('target_end_date_start', null);
        this.set('target_end_date_end', null);
      },
      setCommittedDateRange() {
        const localStartDate = this.get('committedDateStart');
        const localEndDate = this.get('committedDateEnd');
        const localFormat = DATE_FORMAT;
        if (localStartDate) {
          const utcStartDate = moment.utc(moment(localStartDate, localFormat).utc().format('YYYY-MM-DD HH:mm:ssZ'));
          this.set('committed_start_date_start', utcStartDate.format(moment.defaultFormat));
        } else {
          this.set('committed_start_date_start', null);
        }
        if (localEndDate) {
          const utcEndDate = moment.utc(moment(localEndDate, localFormat).endOf('day').utc().format('YYYY-MM-DD HH:mm:ssZ'));
          this.set('committed_start_date_end', utcEndDate.format(moment.defaultFormat));
        } else {
          this.set('committed_start_date_end', null);
        }
      },
      clearCommittedDateRange() {
        this.set('committedDateStart', null);
        this.set('committedDateEnd', null);
        this.set('committed_start_date_start', null);
        this.set('committed_start_date_end', null);
      },
      updateAutocomplete(objectType) {
        let timeoutId = null;
        this.set('autocompleteEnabled', true);
        const requestFunc = () => {
          this.updateAutoCompleteList(this.requestEntity, objectType);
        };
        const handleClickOutside = event => {
          this.handleClickOutsideAutocomplete(event.target, objectType, timeoutId);
        };
        window.addEventListener('click', event => {
          handleClickOutside(event, `${objectType}SearchInput`, timeoutId);
        });
        this.debounceAutocompleteRequest(requestFunc, timeoutId);
        return () => {
          window.removeEventListener('click', handleClickOutside);
          clearTimeout(timeoutId);
        };
      },
      clearSelectedAutocomplete(objectType) {
        this.set(`${objectType}SelectedIds`, []);
        localStorage.setItem(`${objectType}SelectedIds`, JSON.stringify([]));
        this.send('filterAutocomplete', objectType);
      },
      selectAutocomplete(object, objectType) {
        const selectedIds = [...this.get(`${objectType}SelectedIds`), object];
        this.set(`${objectType}SelectedIds`, selectedIds);
        localStorage.setItem(`${objectType}SelectedIds`, JSON.stringify(selectedIds));
        document.querySelector(`#${objectType}Autocomplete`).removeEventListener('input', this.updateAutocomplete);
        this.set(`${objectType}SearchInput`, '');
      },
      filterAutocomplete(objectType) {
        let selected_ids = '';
        this.set(`${objectType}SearchInput`, null);
        for (let object of this.get(`${objectType}SelectedIds`)) {
          selected_ids += `,${object.id}`;
        }
        selected_ids = selected_ids.substring(1);
        this.set(`${objectType}_ids`, selected_ids || null);
      },
      deselectAutocomplete(object, objectType) {
        let selectedIds = this.get(`${objectType}SelectedIds`).filter(selected => object !== selected);
        this.set(`${objectType}SelectedIds`, selectedIds);
        localStorage.setItem(`${objectType}SelectedIds`, JSON.stringify(selectedIds));
        this.set(`${objectType}AutocompleteList`, []);
        const ids = selectedIds.map(object => object.id).join(',');
        this.set(`${objectType}_ids`, ids || null);
      }
    }
  });
});