define("admin/routes/inventories-lists/inventory-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function () {
      var inventoryModel = this.modelFor('inventories-lists');
      return Ember.RSVP.hash({
        inventoryItems: inventoryModel.inventoryItems,
        drones: inventoryModel.drones,
        cameras: inventoryModel.cameras,
        adapters: inventoryModel.adapters,
        otherEquipments: inventoryModel.otherEquipments,
        droneManufacturers: inventoryModel.droneManufacturers,
        groupedItemsList: [Ember.Object.create({
          kind: 'adapters',
          items: [],
          displayName: 'Adapter',
          collapsed: true
        }), Ember.Object.create({
          kind: 'cameras',
          items: [],
          displayName: 'Camera',
          collapsed: true
        }), Ember.Object.create({
          kind: 'drones',
          items: [],
          displayName: 'Drone',
          collapsed: true
        }), Ember.Object.create({
          kind: 'other-equipment',
          items: [],
          displayName: 'Other Equipment',
          collapsed: true
        }), Ember.Object.create({
          kind: 'archived',
          items: [],
          displayName: 'Archived Items',
          collapsed: true
        })]
      });
    },
    setupController: function (controller, model) {
      this.controllerFor('inventories-lists').set('activeTab', 'inventory-list');
      this._super(controller, model);
    }
  });
});