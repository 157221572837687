define("admin/components/schedule-inputs", ["exports", "moment-timezone", "admin/data/schedule-options"], function (_exports, _momentTimezone, _scheduleOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    availableTimes: _scheduleOptions.availableTimes,
    disableWidgetParent: false,
    datepickerParent: Ember.computed('disableWidgetParent', function () {
      if (this.get('disableWidgetParent')) {
        return null;
      } else {
        return Ember.$('.mission-content-with-sidebar__right');
      }
    }),
    didInsertElement: function () {
      this.$('.startDatePicker').datetimepicker({
        collapse: true,
        focusOnShow: false,
        viewMode: 'days',
        format: 'MM/DD/YYYY',
        useCurrent: false,
        minDate: (0, _momentTimezone.default)().startOf('day'),
        widgetParent: this.get('datepickerParent'),
        icons: {
          date: "fa fa-calendar",
          previous: "fa fa-angle-left",
          next: "fa fa-angle-right",
          close: "icon-Xmark"
        }
      });
      this.$('.endDatePicker').datetimepicker({
        collapse: true,
        focusOnShow: false,
        viewMode: 'days',
        format: 'MM/DD/YYYY',
        useCurrent: false,
        minDate: (0, _momentTimezone.default)().startOf('day'),
        widgetParent: this.get('datepickerParent'),
        icons: {
          date: "fa fa-calendar",
          previous: "fa fa-angle-left",
          next: "fa fa-angle-right",
          close: "icon-Xmark"
        }
      });
      Ember.$('.startDatePicker').on('dp.change', e => {
        Ember.$('.endDatePicker').val(e.target.value);
        return this.set('scheduledEndDate', e.target.value);
      });
      this.send('formDateTimeString', this.get('scheduledStartDate'), this.get('scheduledStartTime'), 'selectedStart');
      this.send('formDateTimeString', this.get('scheduledEndDate'), this.get('scheduledEndTime'), 'selectedEnd');
    },
    scheduledStartDate: Ember.computed('mission.scheduled_at_start', function () {
      if (this.get('mission.scheduled_at_start')) {
        return _momentTimezone.default.tz(this.get('mission.scheduled_at_start'), this.get('mission.location.timezone_id')).format('MM/DD/YYYY');
      }
    }),
    scheduledEndDate: Ember.computed('mission.scheduled_at_end', function () {
      if (this.get('mission.scheduled_at_end')) {
        return _momentTimezone.default.tz(this.get('mission.scheduled_at_end'), this.get('mission.location.timezone_id')).format('MM/DD/YYYY');
      }
    }),
    scheduledStartTime: Ember.computed('mission.scheduled_at_start', function () {
      if (this.get('mission.scheduled_at_start')) {
        return _momentTimezone.default.tz(this.get('mission.scheduled_at_start'), this.get('mission.location.timezone_id')).format('hh:mm A');
      }
    }),
    scheduledEndTime: Ember.computed('mission.scheduled_at_end', function () {
      if (this.get('mission.scheduled_at_end')) {
        return _momentTimezone.default.tz(this.get('mission.scheduled_at_end'), this.get('mission.location.timezone_id')).format('hh:mm A');
      }
    }),
    selectedStartObserver: Ember.observer('scheduledStartDate', 'scheduledStartTime', function () {
      if (this.get('scheduledStartDate') && this.get('scheduledStartTime')) {
        return this.send('formDateTimeString', this.get('scheduledStartDate'), this.get('scheduledStartTime'), 'selectedStart');
      }
    }),
    selectedEndObserver: Ember.observer('scheduledEndDate', 'scheduledEndTime', function () {
      if (this.get('scheduledEndDate') && this.get('scheduledEndDate')) {
        return this.send('formDateTimeString', this.get('scheduledEndDate'), this.get('scheduledEndTime'), 'selectedEnd');
      }
    }),
    actions: {
      setStartTime: function (time) {
        var date, duration;
        this.set('scheduledStartTime', time);
        if (this.get('scheduledEndTime') === void 0) {
          duration = this.get('mission.durationInHours');
          date = (0, _momentTimezone.default)(this.get('selectedStart')).toDate();
          date.setHours(date.getHours() + duration);
          return this.set('scheduledEndTime', (0, _momentTimezone.default)(date).format('hh:mm A'));
        }
      },
      setEndTime: function (time) {
        return this.set('scheduledEndTime', time);
      },
      formDateTimeString: function (date, time, writeTo) {
        var choice, timeString;
        if (!(date && time)) {
          return;
        }
        timeString = date + " " + time;
        choice = (0, _momentTimezone.default)(timeString).format('YYYY-MM-DD HH:mm').toString();
        return this.set(writeTo, choice);
      }
    }
  });
});