define("admin/components/mission-assets-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    queueLookup: Ember.inject.service('upload-queue-manager'),
    attachmentNames: [],
    max_file_size: 0,
    multiple: true,
    classNames: ['mission-assets-uploader'],
    onfileadd: null,
    error: '',
    extensions: [],
    config: Ember.computed(function () {
      const config = {
        url: true,
        browse_button: this.get('queueName'),
        filters: {
          max_file_size: this.get('max_file_size'),
          prevent_duplicates: true,
          mime_types: [{
            extensions: this.get('extensions')
          }]
        },
        multi_selection: true,
        runtimes: "html5,html4",
        container: this.get('elementId'),
        uploadCount: this.get('uploadedCount'),
        prefix: this.get('prefix'),
        maxFiles: this.get('maxFiles'),
        attachmentNames: this.get('uploadedAttachmentNames')
      };
      const filters = this.get('fileFilters') || {};
      Object.keys(filters).forEach(filter => config.filters[filter] = filters[filter]);
      return config;
    }),
    attachUploadQueue() {
      return setTimeout(() => {
        if (this.isDestroyed) {
          return;
        }
        const queueLookup = this.get('queueLookup');
        const queue = queueLookup.findOrCreate(this.get('queueName'), this, this.get('config'));
        return this.set('queue', queue);
      }, 1000);
    },
    didInsertElement() {
      this._super(...arguments);
      this.set('error', '');
      return Ember.run.scheduleOnce('afterRender', this, 'attachUploadQueue');
    },
    onerror(target, error) {
      console.error('mission-assets-uploader onerror', error);
      target.set('error', error.message);
      return setTimeout(() => {
        return target.set('error', '');
      }, 5000);
    },
    toggleAllRaw() {
      // FIXME: all row should check all raw, not toggle them
      return Ember.$('.file-edited > input').trigger('click');
    },
    willDestroyElement() {
      const queue = this.get('queue');
      if (queue) {
        try {
          queue.uploader.destroy();
        } catch (error) {
          console.log(`Could not destroy uploader: ${error.message}`);
          console.debug(error.stack);
        }
        return this.set('queue', null);
      }
    },
    actions: {
      removeAsset(uploader, file) {
        return this.onfileremove(uploader, file);
      },
      startUpload(uploader) {
        return this.onstartupload(uploader);
      }
    }
  });
});