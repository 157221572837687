define("admin/adapters/mission-reschedule", ["exports", "admin/adapters/application", "admin/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: `${_environment.default.api.host}/${_environment.default.api.namespace}/admin`,
    urlTemplate: '{+namespace}/missions/{missionId}/schedule{/id}',
    urlSegments: {
      missionId(type, id, snapshot) {
        if (snapshot.modelName) {
          return snapshot.belongsTo('mission', {
            id: true
          });
        } else {
          return snapshot.get('mission.id');
        }
      }
    },
    delete(model) {
      const url = this.buildURL('mission-reschedule', '', model);
      return this.ajax(url, 'DELETE');
    },
    clear(model) {
      const url = this.buildURL('mission-reschedule', 'reset', model);
      return this.ajax(url, 'DELETE');
    }
  });
});