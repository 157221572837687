define("admin/routes/badges/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function () {
      return Ember.RSVP.hash({
        badges: this.store.query('badge', {}),
        badge: this.store.createRecord('badge'),
        trainingPackages: this.store.findAll('training_package'),
        checkrPackages: this.store.findAll('checkr_package'),
        mindflashSeries: this.store.query('mindflash_series', {
          mindflashRefresh: false
        })
      });
    },
    actions: {
      viewBadge: function (badgeId) {
        this.transitionTo('badges.edit', badgeId);
      },
      close() {
        return this.send('closeModal');
      },
      newBadge(model) {
        return this.send('openModal', 'badges.new', model);
      },
      saveBadge(model) {
        var _this = this;
        return model.badge.save().then(function () {
          _this.send('closeModal');
          _this.refresh();
        }).catch(function (response) {
          _this.set('errors', response.errors);
          _this.set('status', 'error');
        });
      }
    }
  });
});