define("admin/routes/badges/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    model(params) {
      return Ember.RSVP.hash({
        drones: this.get('store').findAll('drone'),
        devices: this.get('store').findAll('device'),
        equipments: this.get('store').findAll('pilot_equipment'),
        licenses: this.get('store').findAll('license'),
        badge: this.store.findRecord('badge', params.badge_id),
        backgroundChecks: [],
        trainingPackages: this.store.findAll('training_package'),
        checkrPackages: this.store.findAll('checkr_package'),
        mindflashSeries: this.store.query('mindflash_series', {
          mindflashRefresh: false
        }),
        dispatchLimit: [{
          "id": 20,
          "name": 20
        }, {
          "id": 50,
          "name": 50
        }].map(function (value) {
          return Ember.Object.create(value);
        })
      });
    }
  });
});