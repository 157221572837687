define("admin/components/mission/upload-required-button", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UploadRequiredButtonComponent = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    classNames: 'upload-require-button',
    classNameBindings: ['isHidden:hide'],
    isHidden: false,
    isSendingRequest: false,
    showAssetRejectionModal: false,
    rejectionData: {
      clear_shots: true
    },
    showButton: Ember.computed('mission.status', 'mission.auto_ortho_stitching', function () {
      return this.get('mission.status') === 'in_production' || this.get('mission.auto_ortho_stitching') && this.get('mission.status') === 'assets_classified';
    }),
    statusObserver: Ember.observer('showButton', function () {
      if (this.get('showButton')) {
        this.set('isHidden', false);
      } else {
        this.set('isHidden', true);
      }
    }),
    actions: {
      openAssetRejectionModal() {
        return this.set('showAssetRejectionModal', true);
      },
      closeAssetRejectionModal() {
        return this.set('showAssetRejectionModal', false);
      },
      setRejectionReason(reasonSlug) {
        this.get('mission').set('selected_rejection_reason_slug', reasonSlug);
        return this.set('rejectionData.reason_id', reasonSlug);
      },
      submitStatus() {
        if (this.get('mission.isOnHold') || this.get('isSendingRequest') || !this.get('showAssetRejectionModal')) {
          return;
        }
        this.set('isSendingRequest', true);
        const request = Ember.$.ajax({
          url: `${_environment.default.api.host}/v1/admin/missions/${this.get('mission.id')}/asset_rejections`,
          type: 'POST',
          data: this.get('rejectionData'),
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        });
        request.then(() => {
          this.get('mission').reload();
          this.set('isSendingRequest', false);
          this.set('showAssetRejectionModal', false);
        }, () => this.set('isSendingRequest', false));
        if (this.get('onBusy')) {
          this.get('onBusy')(request);
        }
      }
    }
  });
  var _default = _exports.default = UploadRequiredButtonComponent;
});