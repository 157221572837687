define("admin/data/standby-hours/missing-standby-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    approved: 'There are no standby hours to approve',
    rejected: 'There are no standby hours to reject',
    in_review: 'There are no standby hours to review'
  };
});