define("admin/data/standby-hours/standby-hour-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    label: 'All',
    value: 'all'
  }, {
    label: 'Approved',
    value: 'approved'
  }, {
    label: 'Rejected',
    value: 'rejected'
  }, {
    label: 'In Review',
    value: 'in_review'
  }];
});