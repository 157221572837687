define("admin/components/shot-list/shot-list-form", ["exports", "admin/config/environment", "admin/validations/shot-list", "admin/validations/shot-type", "ember-changeset-validations", "ember-changeset", "admin/data/shot-type/shot-template-types"], function (_exports, _environment, _shotList, _shotType, _emberChangesetValidations, _emberChangeset, _shotTemplateTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    classNames: 'shot-list-form',
    buttonText: 'Submit',
    shotTypes: _shotTemplateTypes.default,
    router: Ember.inject.service(),
    validations: {
      'shot-list.name': {
        presence: true,
        length: {
          minimum: 3,
          maximum: 75
        }
      }
    },
    initChangeSet: Ember.on('init', function () {
      return this.changeset = new _emberChangeset.default(this.get('shot_list'), (0, _emberChangesetValidations.default)(_shotList.default), _shotList.default);
    }),
    didInsertElement() {
      return Ember.$('html,body').animate({
        scrollTop: 0
      }, 400);
    },
    reversedShotTypesList: Ember.computed('shot_list.shot_types.[]', function () {
      return this.get('shot_list.shot_types').toArray().reverse();
    }),
    selectedType: Ember.computed('reversedShotTypesList', function () {
      return this.get('reversedShotTypesList.firstObject');
    }),
    newShotTypeObserver: Ember.observer('newShotType', function () {
      const newShotType = this.get('newShotType');
      if (!newShotType) {
        return;
      }
      return this.saveShotType(this.get('selectedType')).then(saved => {
        this.set('newShotType', null);
        if (saved) {
          return this.addShotType(newShotType);
        }
      }).catch(() => {
        return this.set('newShotType', null);
      });
    }),
    addShotType(shot_type_template) {
      let shotTypeName = shot_type_template.replace(/_/g, ' ');
      shotTypeName = shotTypeName.charAt(0).toUpperCase() + shotTypeName.slice(1);
      const shotType = this.get('shot_list.shot_types').createRecord({
        template: shot_type_template,
        name: shotTypeName
      });
      return this.set('selectedType', shotType);
    },
    saveShotList() {
      const isNewShotList = this.get('shot_list.isNew');
      return this.changeset.validate().then(() => {
        if (this.changeset.get('isInvalid')) {
          Ember.$('.name-error').show();
          return;
        } else {
          return this.changeset.save().then(() => {
            Ember.$('.name-error').hide();
            this.flashMessages.success('Shot List saved successfully.');
            return Ember.run.later(() => {
              if (isNewShotList) {
                return this.get('router').transitionTo(`/shot_lists/${this.get('shot_list.id')}/edit`);
              }
            }, 1000);
          });
        }
      });
    },
    saveShotType(shotType) {
      if (Ember.isPresent(shotType) && shotType.get('hasDirtyAttributes')) {
        const changeset = new _emberChangeset.default(shotType, (0, _emberChangesetValidations.default)(_shotType.default), _shotType.default);
        return changeset.validate().then(() => {
          let request;
          if (changeset.get('isInvalid')) {
            Ember.$('.shot-type-form .name-error').show();
            request = new Ember.RSVP.Promise(function (resolve, reject) {
              resolve(false);
              return reject();
            });
          } else {
            Ember.$('.shot-type-form .name-error').hide();
            request = changeset.save().then(() => {
              return this.set('cancelDisabled', true);
            });
          }
          if (this.get('onBusy')) {
            this.get('onBusy')(request);
          }
          return request;
        });
      } else {
        let request;
        return request = new Ember.RSVP.Promise(function (resolve, reject) {
          resolve(true);
          return reject();
        });
      }
    },
    clearRemovedType(shotType) {
      shotType.set('shot_list', null);
      shotType.deleteRecord();
      this.set('selectedType', this.get('reversedShotTypesList.firstObject'));
      return Ember.$('html,body').animate({
        scrollTop: 0
      }, 400);
    },
    cancelSave(currentRoute) {
      return currentRoute.transitionTo('shot_lists.index');
    },
    actions: {
      saveShotListAction(selectShotType) {
        return this.saveShotType(selectShotType).then(saved => {
          if (saved) {
            return this.saveShotList();
          }
        });
      },
      saveShotTypeAction(shotType) {
        return this.saveShotType(shotType);
      },
      removeShotType() {
        const shotType = this.get('selectedType');
        if (confirm('Are you sure you want to remove this shot type?')) {
          if (shotType.isNew) {
            return this.clearRemovedType(shotType);
          } else {
            return Ember.$.ajax({
              url: `${_environment.default.api.host}/v1/admin/shot_types/${this.get('selectedType.id')}/unassign_shot_type`,
              headers: this.get('sessionAccount.headers'),
              type: 'POST',
              dataType: 'json'
            }).then(() => {
              return this.clearRemovedType(shotType);
            });
          }
        }
      },
      closeSavedModal() {
        return this.set('showSavedModal', false);
      }
    }
  });
});