define("admin/serializers/serializeable-relationships", ["exports", "admin/serializers/application", "ember-data/-private"], function (_exports, _application, _private2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    serializeHasMany(snapshot, json, relationship) {
      if (relationship.options['serialize'] == 'object') {
        this.__serializeHasManyAsObject(...arguments);
      } else {
        this._super(...arguments);
      }
    },
    __serializeHasManyAsObject(snapshot, json, relationship) {
      var key = relationship.key;
      if (this.shouldSerializeHasMany(snapshot, key, relationship)) {
        var hasMany = snapshot.hasMany(key);
        if (hasMany !== undefined) {
          json.relationships = json.relationships || {};
          var payloadKey = this._getMappedKey(key, snapshot.type);
          if (payloadKey === key && this.keyForRelationship) {
            payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
          }
          var data = new Array(hasMany.length);
          for (var i = 0; i < hasMany.length; i++) {
            var item = hasMany[i];
            var payloadType = void 0;
            if ((0, _private2.default.isEnabled)("ds-payload-type-hooks")) {
              payloadType = this.payloadTypeFromModelName(item.modelName);
              var deprecatedPayloadTypeLookup = this.payloadKeyFromModelName(item.modelName);
              if (payloadType !== deprecatedPayloadTypeLookup && this._hasCustomPayloadKeyFromModelName()) {
                true && !false && deprecate("You used payloadKeyFromModelName to serialize type for belongs-to relationship. Use payloadTypeFromModelName instead.", false, {
                  id: 'ds.json-api-serializer.deprecated-payload-type-for-has-many',
                  until: '4.0.0'
                });
                payloadType = deprecatedPayloadTypeLookup;
              }
            } else {
              payloadType = this.payloadKeyFromModelName(item.modelName);
            }
            data[i] = {
              type: payloadType,
              id: item.id,
              attributes: item.attributes()
            };
          }
          json.relationships[payloadKey] = {
            data
          };
        }
      }
    }
  });
});