define("admin/controllers/badges/edit", ["exports", "admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    showBadgeDispatchModal: false,
    sessionAccount: Ember.inject.service(),
    validateRecord() {
      var zones = this.get('model.badge.badge_dispatch_zones');
      if (this.get('model.badge.auto_dispatch_enabled') && zones.length <= 0) {
        return false;
      }
      return zones.toArray().every(function (zone) {
        return zone.validate();
      });
    },
    loadPilotCount() {
      var self = this;
      var badgeId = this.get('model.badge.id');
      var endPoint = `${_environment.default.api.host}/v1/admin/badges/${badgeId}/estimate_pilot_count`;
      Ember.$.ajax({
        url: endPoint,
        type: 'POST',
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        headers: this.get('sessionAccount.headers'),
        data: JSON.stringify(this.get('model.badge').serialize())
      }).then(response => {
        self.set('pilotPreview', response['count']);
      }, () => {});
    },
    observeFilters: Ember.observer('model.badge.required_drones', 'model.badge.required_drone_cameras', 'model.badge.required_devices', 'model.badge.required_pilot_equipments', 'model.badge.required_pilot_licenses', 'model.badge.badge_dispatch_zones', 'model.badge.required_drones.@each', 'model.badge.required_drone_cameras.@each', 'model.badge.required_devices.@each', 'model.badge.required_pilot_equipments.@each', 'model.badge.required_pilot_licenses.@each', 'model.badge.badge_dispatch_zones.@each', 'model.badge.badge_dispatch_zones.@each.latitude', 'model.badge.badge_dispatch_zones.@each.longitude', 'model.badge.badge_dispatch_zones.@each.distance', function () {
      this.set('pilotPreview', '...');
      if (this.validateRecord()) {
        this.set('countRunner', Ember.run.debounce(this, this.loadPilotCount, 2500));
      } else {
        var runner = this.get('countRunner');
        if (runner) {
          Ember.run.cancel(runner);
        }
      }
    }),
    actions: {
      validate: function () {
        return this.validateRecord();
      },
      save(badge) {
        var _this = this;
        if (this.get("locked")) {
          alert("please wait");
          return;
        }
        this.set('locked', true);
        badge.save().then(() => {
          this.set('locked', false);
          badge.get('badge_dispatch_zones').forEach(zone => {
            if (zone && zone.get('id') === null) {
              Ember.run.later(() => {
                _this.get('model.badge.badge_dispatch_zones').removeObject(zone);
              }, 1);
            }
          });
        }).catch(err => {
          console.log(err);
          this.set('locked', false);
          alert("FAILED TO SAVE");
        });
      },
      setAutoDispatchBatchSize(event) {
        this.get('model.badge').set('auto_dispatch_batch_size', Ember.$(event.target).val());
      },
      toggleModal(disableAutoDispatch) {
        if (disableAutoDispatch === true) {
          this.set('model.badge.auto_dispatch_enabled', false);
        }
        this.toggleProperty('showBadgeDispatchModal');
      },
      autoDispatchToggled() {
        var newValue = !this.get('model.badge.auto_dispatch_enabled');
        this.set('model.badge.auto_dispatch_enabled', newValue);
        if (newValue) {
          if (!this.validateRecord()) {
            newValue = true;
            this.toggleProperty('showBadgeDispatchModal');
          }
        }
      }
    }
  });
});