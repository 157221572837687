define("admin/components/asset-share-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    buttonText: null,
    shareLink: Ember.computed('shareable.shareLink', function () {
      return this.get('shareable.shareLink');
    }),
    isShareable: Ember.computed('shareable.isShareable', function () {
      return this.get('shareable.isShareable');
    }),
    actions: {
      hideShareModal() {
        return this.hideShareModal();
      },
      copyShareLink() {
        const copyText = document.getElementById('mission-share-link');
        copyText.select();
        document.execCommand("copy");
        this.set('textCopied', true);
        setTimeout(() => {
          this.set('textCopied', false);
        }, 3000);
      }
    }
  });
});