define("admin/components/missions/full-reshoot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    copyShots: null,
    priceZero: null,
    cantSetParentToZero: Ember.computed('model.mission.status', function () {
      return ['invoiced', 'complete'].includes(this.get('model.mission.status'));
    }),
    isMissionInvoicedOrInvoiceNeeded: Ember.computed('model.mission.status', function () {
      return ['invoiced', 'invoice_needed'].includes(this.get('model.mission.status'));
    }),
    actions: {
      setRejectionReason(reasonSlug) {
        this.get('setRejectionReasonSlug')(reasonSlug);
        return this.set('rejectionReason', reasonSlug);
      },
      setPriceZero(value) {
        return this.set('priceZero', value);
      },
      setCopyShots(value) {
        return this.set('copyShots', value);
      },
      close() {
        return this.close();
      },
      reshoot(model) {
        let left;
        if (!Ember.$('.form-horizontal select').val()) {
          Ember.$(Ember.$('.error-message')[0]).show();
        } else {
          Ember.$(Ember.$('.error-message')[0]).hide();
        }
        if (this.get('priceZero') === null) {
          Ember.$(Ember.$('.error-message')[1]).show();
        } else {
          Ember.$(Ember.$('.error-message')[1]).hide();
        }
        if (this.get('copyShots') === null) {
          Ember.$(Ember.$('.error-message')[2]).show();
        } else {
          Ember.$(Ember.$('.error-message')[2]).hide();
        }
        if (!Ember.$('.form-horizontal select').val() || this.get('copyShots') === null || this.get('priceZero') === null) {
          return;
        }
        const planned_date = Ember.$('.delivery-requirements .planned-date input').val();
        const promised_date = Ember.$('.delivery-requirements .promised-date input').val();
        const data = {
          planned_delivery_date: planned_date,
          promised_delivery_date: promised_date,
          reason_id: this.get('rejectionReason'),
          reason: this.get('rejectionNotes'),
          copy_shots: (left = this.get('copyShots')) != null ? left : {
            true: null
          },
          price_zero: this.get('priceZero')
        };
        this.get('close')();
        return this.reshootAction(model, data);
      }
    }
  });
});